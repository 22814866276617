import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';

const homeRoutes: Routes  = [
    {
		path: '',
		component:HomeComponent
	},
    // {
	// 	path: 'home',
	// 	component:HomeComponent
	// },
];

@NgModule({
  imports: [RouterModule.forChild(homeRoutes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
