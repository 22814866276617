import { Injectable, WritableSignal, signal } from "@angular/core";

@Injectable()
export class SearchContextService {
    public readonly selectedSpecialities: WritableSignal<string[]> = signal([]);
    public readonly selectedContentType: WritableSignal<string[]> = signal([]);
    public readonly searchContent: WritableSignal<string | undefined> = signal(undefined)

    public setSelectedSpecialities(data: string[]): void {
        this.selectedSpecialities.update(s => data);
    }

    public setSelectedContentType(data: string[]): void {
        this.selectedContentType.update(s => data);
    }

    public setSearchContent(data: string | undefined): void {
        this.searchContent.update(s => data);
    }
    clearFilters() {
        this.selectedSpecialities(); // Assuming selectedSpecialities is a BehaviorSubject or similar
        this.selectedContentType();  // Assuming selectedContentType is a BehaviorSubject or similar
      }
}